import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';

type StateProps = {
  giftName?: string;
  eventName?: string;
  brand?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
};

export const initialState: StateProps = {
  giftName: '',
  eventName: '',
  brand: '',
  fromDate: null,
  toDate: null,
};

export const eventHistoryPrize = createSlice({
  name: 'eventAdmin',
  initialState,
  reducers: {
    setSeacrhParams: (state, action: PayloadAction<StateProps>) => {
      state.giftName = action.payload.giftName;
      state.eventName = action.payload.eventName;
      state.brand = action.payload.brand;
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
  },
});

export const { setSeacrhParams } = eventHistoryPrize.actions;

export const searchParamsSelector = (state: RootState) => {
  return {
    giftName: state.eventHistoryPrizeV2.giftName,
    eventName: state.eventHistoryPrizeV2.eventName,
    brand: state.eventHistoryPrizeV2.brand,
    fromDate: state.eventHistoryPrizeV2.fromDate,
    toDate: state.eventHistoryPrizeV2.toDate,
  };
};

export default eventHistoryPrize.reducer;
